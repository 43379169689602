import React from "react"

export const appStoreUrl = `https://apps.apple.com/app/id1489512510`
export const appTitle = `Power Failure & Outage Alarm`
export const appSubtitle = 'Blackout Notifications & Logs'
export const appPromo = <div style={{ textAlign: "left" }}>
Outage Alarm lets you know when the power is cut.<br />
• Works in the background<br />
• Options for sound, visual, and email alerts<br />
• Receive email when power is cut or restored<br />
• Monitors and logs when power is cut or restored<br />
• Can automatically turn on torch (use as an emergency light!)<br />
• Adjust minimum outage duration sensitivity to avoid detecting micro cuts<br />
<br />
HOW TO USE:<br />
• Connect your device to a power adapter and arm the alarm.<br />
That's it! If the power goes out, an alarm will go off.<br />
<br />
The alarm can be used in the background and with the device locked, just keep in mind these tips:<br />
• Do not terminate the app from "recently opened apps"!<br />
• Try not to use any apps that are demanding of your phone's resources while the alarm is armed, such as music, video, and games.<br />
<br />
If there is a flood or a storm coming, or if you have delicate electrical equipment, this app comes in handy. You can go to sleep with a peace of mind, knowing that if there is an outage, the app will wake you up!<br />
Control the alarm sensitivity to choose how long an outage takes before the alarm goes off to avoid detecting power blips.<br />
<br />
You can also set up email alerts for remote monitoring, and keep an eye on your power when you're at work.
The app monitors both power cuts and restorations, and provides comprehensive logs which you can sort and filter, so you can avoid unpleasant surprises and take control.<br />
<br />
You can also use this app as a handy anti-theft alarm if you are charging your device in a public space (like an airport)! If somebody disconnects your device from the charging cable, the alarm will sound.<br />
</div>